const auraImages = [];
let startImageNum = '0000';
for (let i = 0; i < 91; i += 1) {
  if (i >= 10 && i < 100) {
    startImageNum = '000';
  } else if (i >= 100) {
    startImageNum = '00';
  }
  auraImages.push(`/images/Aurora/aura_${startImageNum}${i}-min.png`);
}

export default auraImages;
