const butterflyImages = [];
let startImageNum = '0000';
for (let i = 0; i < 250; i += 1) {
  if (i >= 10 && i < 100) {
    startImageNum = '000';
  } else if (i >= 100) {
    startImageNum = '00';
  }
  butterflyImages.push(`/images/Butterfly/butterfly_${startImageNum}${i}-min.png`);
}

export default butterflyImages;
