import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { MenuLogo, MenuCloseIcon } from './Icons';
import media from '../utils/media';
import ContactForm from './ContactForm';
import MenuNavigation from './MenuNavigation';

const Menu = (props) => {
  const {
    isMenuActive,
    setMenuActive,
    currentPage,
    setCurrentPage,
    currentType,
    setCurrentType,
    isContactFormActive,
    setContactFormActive,
    successMessage,
    setSuccessMessageActive,
    setLensActive,
    setCurrentItem,
  } = props;
  return (
    <Container
      currentPage={currentPage}
      isMenuActive={isMenuActive}
    >
      <MenuIcon
        isActive={isMenuActive}
        onClick={() => {
          setMenuActive(!isMenuActive);
          setContactFormActive(!isContactFormActive);
          setLensActive(false);
        }}
      >
        {isMenuActive ? <MenuCloseIcon /> : <MenuLogo />}
      </MenuIcon>
      <MenuBody
        isActive={isMenuActive}
      >
        <MenuNavigation
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isMenuActive={isMenuActive}
          setMenuActive={setMenuActive}
          currentType={currentType}
          setCurrentType={setCurrentType}
          setContactFormActive={setContactFormActive}
          isContactFormActive={isContactFormActive}
          setCurrentItem={setCurrentItem}
        />
        <ContactForm
          isContactFormActive={isContactFormActive}
          setContactFormActive={setContactFormActive}
          successMessage={successMessage}
          setSuccessMessageActive={setSuccessMessageActive}
          isMenuActive={isMenuActive}
          setMenuActive={setMenuActive}
          isMobileActive
        />
      </MenuBody>
    </Container>
  );
};

Menu.propTypes = {
  isMenuActive: propTypes.bool.isRequired,
  setMenuActive: propTypes.func.isRequired,
  setCurrentPage: propTypes.func.isRequired,
  setCurrentType: propTypes.func.isRequired,
  currentPage: propTypes.number.isRequired,
  currentType: propTypes.number.isRequired,
  isContactFormActive: propTypes.bool.isRequired,
  setContactFormActive: propTypes.func.isRequired,
  successMessage: propTypes.bool.isRequired,
  setSuccessMessageActive: propTypes.func.isRequired,
  setLensActive: propTypes.func.isRequired,
  setCurrentItem: propTypes.func.isRequired,
};

export default Menu;

const Container = styled.div`
  display: none;
  
  ${media.tablet`
    display: block;
    position: ${({ currentPage, isMenuActive }) => (currentPage || isMenuActive ? 'absolute' : 'static')};
    top: 10px;
    left: 14px;
  `}
`;

const MenuIcon = styled.div`
  display: flex;
  cursor: pointer;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? '#000' : '#fff')};
  border-radius: 50%;
  position: relative;
  z-index: 200;
   @media (orientation: landscape) and (max-height: 200px) {
     display: none;
   }
`;

const MenuBody = styled.div`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(200, 186, 131, 0.9);
  padding-top: 70px;
  padding-bottom: 38px;
  @media (max-height: 200px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  ${media.mobile`
    @media (orientation: landscape) {
      padding-bottom: 7.1px;
    }
  `}
`;
