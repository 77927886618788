import React, { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { isMobile } from 'is-mobile';
import LinkedInLogo from '../assets/images/linkedIn.png';
import media from '../utils/media';

const ContactForm = (props) => {
  const {
    setContactFormActive,
    isContactFormActive,
    successMessage,
    setSuccessMessageActive,
    setMenuActive,
    isMobileActive,
  } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorActive, setErrorActive] = useState(false);
  const container = useRef(null);
  const name = useRef(null);
  const email = useRef(null);
  const phone = useRef(null);
  const comments = useRef(null);

  useEffect(() => {
    const closeForm = (e) => {
      if (e.target.tagName.toLowerCase() !== 'input' && e.target.tagName.toLowerCase() !== 'textarea' && e.target.tagName.toLowerCase() !== 'button') {
        setContactFormActive(false);
      }
    };
    if (isContactFormActive && !isMobile()) {
      document.body.addEventListener('click', closeForm, { capture: true });
    }
    const focusHandler = (e) => {
      e.target.focus();
    };
    const nameField = name.current;
    const emailField = email.current;
    const phoneField = phone.current;
    const commentsField = comments.current;
    if (name.current && isMobile()) {
      nameField.addEventListener('click', focusHandler, false);
      emailField.addEventListener('click', focusHandler, false);
      phoneField.addEventListener('click', focusHandler, false);
      commentsField.addEventListener('click', focusHandler, false);
    }
    return () => {
      document.body.removeEventListener('click', closeForm, { capture: true });
      nameField.removeEventListener('click', focusHandler, false);
      emailField.removeEventListener('click', focusHandler, false);
      phoneField.removeEventListener('click', focusHandler, false);
      commentsField.removeEventListener('click', focusHandler, false);
    };
  }, [
    container,
    setContactFormActive,
    isContactFormActive,
  ]);

  const validateEmail = (value) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!value.match(re);
  };
  const validatePhone = (value) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    return !!value.match(re);
  };
  const checkErrors = () => {
    if (!validateEmail(email.current.value) || !validatePhone(phone.current.value)) {
      setErrorMessage('Please put valid data');
      setErrorActive(true);
    }
    if (validateEmail(email.current.value) && validatePhone(phone.current.value)) {
      setErrorActive(false);
    }
    return validateEmail(email.current.value) && validatePhone(phone.current.value);
  };
  const changeHandler = (e) => {
    checkErrors();
    if (e.target.value === '') {
      setErrorActive(false);
    }
  };
  const handlerSubmit = (e) => {
    e.preventDefault();
    const res = [
      {
        name: 'name',
        value: name.current.value,
      },
      {
        name: 'email',
        value: email.current.value,
      },
      {
        name: 'phone',
        value: phone.current.value,
      },
      {
        name: 'comments',
        value: comments.current.value,
      },

    ];
    if (checkErrors()) {
      const formData = new FormData();
      res.forEach(({ name: _name, value }) => {
        formData.append(_name, value);
      });
      const body = res.reduce((acc, item, i) => `${acc}${item.name}=${encodeURIComponent(item.value)}${i === res.length - 1 ? '' : '&'}`, '');
      fetch(`${process.env.PUBLIC_URL}/mail.php?${body}`, {
        body: formData,
        method: 'POST',
      })
        .then(result => result.text())
        .then(result => console.log(result));
      name.current.value = '';
      email.current.value = '';
      phone.current.value = '';
      comments.current.value = '';
      setContactFormActive(false);
      setMenuActive(false);
      setSuccessMessageActive(!successMessage);
    }
  };
  return (
    <Container
      isActive={isContactFormActive}
      isMobileActive={isMobileActive}
    >
      <Form
        ref={container}
        method="post"
        onSubmit={handlerSubmit}
      >
        <ErrorField
          isActive={isErrorActive}
        >
          {errorMessage}
        </ErrorField>
        <FieldsContainer>
          <InputContainer>
            <InputField
              placeholder="Name"
              name="name"
              type="text"
              ref={name}
              required
            />
            <InputField
              placeholder="Email"
              name="email"
              type="text"
              ref={email}
              required
              onChange={e => changeHandler(e)}
            />
            <InputField
              placeholder="Phone"
              name="phone"
              type="tel"
              ref={phone}
              required
              onChange={e => changeHandler(e)}
            />
          </InputContainer>
          <TextField
            placeholder="Comments"
            name="comments"
            type="textarea"
            ref={comments}
          />
        </FieldsContainer>
        <Button type="submit">
          SEND
        </Button>
      </Form>
      <InfoBlock>
        <Text>
          FIND ME ON
        </Text>
        <img src={LinkedInLogo} alt="LinkedIn Logo" />
      </InfoBlock>
    </Container>
  );
};

ContactForm.propTypes = {
  setContactFormActive: propTypes.func.isRequired,
  isContactFormActive: propTypes.bool.isRequired,
  successMessage: propTypes.bool.isRequired,
  setSuccessMessageActive: propTypes.func.isRequired,
  setMenuActive: propTypes.func.isRequired,
  isMobileActive: propTypes.bool.isRequired,
};

export default ContactForm;

const Container = styled.div`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 150;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(200, 186, 131, 0.9);
  ${media.tablet`
    display: ${({ isMobileActive }) => (isMobileActive ? 'flex' : 'none')};
    position: relative;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0 7.1px;
    flex-grow: 1;
  `}
`;

const ErrorField = styled.div`
  background-color: rgba(255, 255, 255, .8);
  font-size: 25px;
  padding: 10px 20px;
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  border: 1px solid #ea3e3e;
  color: #ea3e3e;
  ${media.mobile`
    font-size: 14px;
    z-index: 200;
    position: absolute;
  `}
`;

const Form = styled.form`
  min-width: 799px;
  display: flex;
  flex-direction: column;
  
  ${media.tablet`
    flex-basis: 95%;
    min-width: auto;
    width: 100%;
    flex-grow: 1;
    flex-basis: 100%;
    @media (orientation: landscape) {
      flex-basis: 95%;
    }
  `}
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet`
    margin-right: 5px;
    flex-basis: 50%;
    margin-right: 0;
    @media (orientation: landscape) {
      margin-right: 5px;
      flex-basis: 50%;
    }
  `}
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 52%;
  ${media.tablet`
    flex-basis: 70%;
    margin-bottom: 7.1px;
    @media (orientation: landscape) and (max-height: 100px) {
      margin-bottom: 0;
    }
    flex-direction: column;
    @media (orientation: landscape) {
      flex-direction: row;
      margin-bottom: 7.1px;
    }
  `}
`;

const fieldStyle = css`
  background-color: rgba(255, 255, 255, .75);
  min-height: 93px;
  margin-bottom: 2px;
  font-size: 25px;
  padding-left: 33px;
  &::placeholder {
    color: #000;
  }
  &:focus {
    background-color: #fff;
  }
  ${media.tablet`
    min-height: auto;
    font-size: 14px;
    padding-left: 12.1px;
    flex-basis: 33%;
  `}
`;

const InputField = styled.input`
  ${fieldStyle}
`;

const TextField = styled.textarea`
  ${fieldStyle};
  resize: none;
  padding-top: 37px;
  height: 310px;
  @media (orientation: landscape) and (max-height: 768px) {
    height: auto;
    max-height: 310px;
  }
  ${media.tablet`
    height: auto;
    flex-basis: 50%;
    padding-top: 37px;
    @media (orientation: landscape) {
      padding-top: 10px;
    }
  `}
`;

const Button = styled.button`
  min-height: 93px;
  color: #fff;
  text-align: center;
  background-color: rgb(93,152,171, .75);
  font-size: 25px;
  ${media.tablet`
    min-height: auto;
    font-size: 15px;
    background-color: #5d98ab;
    flex-basis: 15%;
    @media (orientation: landscape) {
      flex-basis: 30%;
    }
    ${media.mobile`
      // @media (orientation: landscape) {
      //   flex-basis: 30%;
      // }
    `}
  `}
`;

const InfoBlock = styled.div`
  position: fixed;
  bottom: 34px;
  line-height: 1;
  opacity: .5;
  font-size: 18px;
  font-family: "Source Sans Pro - Semibold", sans-serif;
  img {
    width: 77px;
    height: 20px;
  }
  ${media.tablet`
    position: static;
    opacity: 1;
    // margin: 3% 0;
    // flex-basis: 5%;
    @media (max-height: 200px) {
      display: none;
    }
    margin: 5% 0;
    flex-basis: auto;
    @media (orientation: landscape) {
      margin: 3% 0;
      flex-basis: 5%;
    }
  `}
  ${media.mobile`
    // margin: 5% 0;
    // flex-basis: auto;
    // @media (orientation: landscape) {
    //   margin: 3% 0;
    //   flex-basis: 5%;
    // }
  `}
`;

const Text = styled.span`
  margin-right: 8px;
  line-height: 1.2;
  ${media.tablet`
    text-transform: lowercase;  
  `}
`;
