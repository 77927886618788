import anime from 'animejs';

export default class Effect {
  constructor(src, loop) {
    this.loop = loop;
    this.src = src;
    if (loop) {
      const Ac = (window.webkitAudioContext || AudioContext);
      this.actx = new Ac();

      // eslint-disable-next-line no-inner-declarations
      function unlockAudioContext(context) {
        if (context.state !== 'suspended') return;
        const b = document.body;
        const events = ['touchstart', 'touchend', 'mousedown', 'keydown'];
        // eslint-disable-next-line no-use-before-define
        events.forEach(e => b.addEventListener(e, unlock, false));

        function unlock() {
          // eslint-disable-next-line no-use-before-define
          context.resume().then(clean);
        }

        function clean() {
          events.forEach(e => b.removeEventListener(e, unlock));
        }
      }

      unlockAudioContext(this.actx);
      if (window.webkitAudioContext) {
        fetch(src, { mode: 'cors' })
          .then(res => res.arrayBuffer())
          .then(arrB => this.actx.decodeAudioData(arrB, (audioB) => {
            this.audioBuffer = audioB;
            this.play();
            this.loop = true;
          }));
      } else {
        fetch(src, { mode: 'cors' })
          .then(res => res.arrayBuffer())
          .then(arrB => this.actx.decodeAudioData(arrB))
          .then((audioB) => {
            this.audioBuffer = audioB;
            this.play();
            this.loop = true;
          });
      }
    } else {
      this.audio = new Audio(src);
      this.audio.loop = Boolean(loop);
      this.audio.preload = true;
      this.audio.addEventListener('loadeddata', () => {});
      this.audio.volume = 0;
    }
  }

  setVolume(volume) {
    if (this.gainNode) {
      const vol = {
        value: this.gainNode.gain.value,
      };
      this.gainNode.gain.setValueAtTime(volume, this.actx.currentTime);
      if (this.volumeID) {
        this.volumeID.reset();
        this.volumeID.pause();

        this.volumeID = null;
      }
      this.volumeID = anime({
        targets: vol,
        value: volume,
        easing: 'linear',
        duration: 450,
        update: () => {
          this.gainNode.gain.setValueAtTime(vol.value, this.actx.currentTime);
        },
        complete: () => {
          this.volumeID = null;
        },
      });
    }
  }

  play(hard, percentageStart = 0) {
    if (this.audioBuffer && !this.ctxAudio) {
      this.ctxAudio = this.actx.createBufferSource();
      this.ctxAudio.buffer = this.audioBuffer;
      this.ctxAudio.connect(this.actx.destination);
      this.ctxAudio.loop = true;
      this.gainNode = this.actx.createGain();
      this.gainNode.connect(this.actx.destination);
      this.ctxAudio.connect(this.gainNode);
      this.ctxAudio.start(0);
      this.gainNode.gain.setValueAtTime(-0.5, this.actx.currentTime);
      const volume = {
        value: -1,
      };
      this.playID = anime({
        targets: volume,
        value: -0.5,
        easing: 'linear',
        duration: 450,
        update: () => {
          this.gainNode.gain.setValueAtTime(volume.value, this.actx.currentTime);
        },
        complete: () => {
          this.playID = undefined;
        },
      });
    } else if (this.audio && this.audio.paused) {
      this.audio.play()
        .catch(e => console.dir(e));
      this.audio.currentTime = percentageStart * (this.audio.duration || 0);
      const volume = {
        value: 0,
      };
      if (this.stopID) this.stopID.pause();
      if (hard) {
        this.audio.volume = 0.5;
      } else {
        this.playID = anime({
          targets: volume,
          value: 100,
          // round: 1,
          easing: 'linear',
          update: () => {
            this.audio.volume = volume.value / 100 / 2;
          },
          complete: () => {
            this.playID = undefined;
          },
        });
      }
    }
  }

  pause() {
    if (this.audio) {
      this.audio.pause();
    }
  }

  stop(hard) {
    if (this.ctxAudio && !this.playID) {
      const volume = {
        value: -0.5,
      };

      this.stopID = anime({
        targets: volume,
        value: -1,
        easing: 'linear',
        duration: 450,
        update: () => {
          if (this.gainNode) this.gainNode.gain.setValueAtTime(volume.value, this.actx.currentTime);
        },
        complete: () => {
          if (this.ctxAudio) this.ctxAudio.stop();
          this.gainNode = undefined;
          this.ctxAudio = undefined;
          this.stopID = undefined;
        },
      });
    }
    if (this.audio) {
      const volume = {
        value: 100,
      };
      if (this.playID) this.playID.pause();

      if (hard) {
        this.audio.volume = 0;
        this.audio.currentTime = 0;
        this.audio.pause();
      } else {
        this.stopID = anime({
          targets: volume,
          value: 0,
          // round: 1,
          easing: 'linear',
          update: () => {
            this.audio.volume = volume.value / 100 / 2;
          },
          complete: () => {
            this.audio.currentTime = 0;
            this.audio.pause();
            this.stopID = undefined;
          },
        });
      }
    }
  }
}
