import { css } from 'styled-components';

export const mediaSizes = {
  tablet: 1023,
  mobile: 767,
};

// Iterate through the sizes and create a media template
const media = Object.entries(mediaSizes).reduce((acc, [label, size]) => {
  acc[label] = (...args) => css`
    @media (max-width: ${size}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export const mediaJS = Object.entries(mediaSizes).reduce((acc, [label, size]) => {
  acc[label] = () => window.matchMedia(`(max-width: ${size}px)`).matches;
  return acc;
}, {});

export default media;
