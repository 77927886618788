import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../utils/media';


const AnimatedImage = (props) => {
  const container = React.useRef(null);
  const {
    title,
    coordinates,
    imageSrc,
    isImgAnimated,
    loadImageHelper,
    soundBees,
    soundBird,
  } = props;
  const volume = -0.5;
  const [soundVolume, setSoundVolume] = useState(volume);
  const [soundName, setSoundName] = useState('');
  const changeVolume = () => {
    if (soundName === 'bees') {
      soundBees.setVolume(soundVolume);
    } else if (soundName === 'bird') {
      soundBird.setVolume(soundVolume);
    }
  };
  if (soundVolume === 1) {
    changeVolume();
  } else {
    changeVolume();
  }

  const increaseSound = (e) => {
    if (e.target.src && e.target.src.match(title)) {
      setSoundVolume(1);
      setSoundName(title);
    }
  };
  const decreaseSound = (e) => {
    if (e.target.src && e.target.src.match(title)) {
      setSoundVolume(-0.5);
      setSoundName(title);
    }
  };

  React.useEffect(() => {
    if (container.current) {
      const images = container.current.querySelectorAll('img');
      let i = 0;
      const MAX_IMAGES = images.length - 1;

      const animate = () => {
        // eslint-disable-next-line no-param-reassign
        [...images].forEach(item => item.style.opacity = 0);

        images[i].style.opacity = 1;

        i = i === MAX_IMAGES ? 0 : i + 1;

        // requestAnimationFrame(animate);
        setTimeout(animate, 50);
      };
      animate();
    }
  }, [container]);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <Container
      ref={container}
      coordinatesDesktop={coordinates.desktop}
      coordinatesMobile={coordinates.mobile}
      isAnimated={isImgAnimated}
      onMouseOver={e => increaseSound(e)}
      onMouseLeave={e => decreaseSound(e)}
      onTouchStart={e => increaseSound(e)}
      onTouchEnd={e => decreaseSound(e)}
    >
      {Array.isArray(imageSrc) ? imageSrc.map((path, i) => (
        <img
          key={title + i}
          src={process.env.PUBLIC_URL + path}
          alt={title + i}
          onLoad={loadImageHelper}
        />
      )) : <img src={imageSrc} alt={title} onLoad={loadImageHelper} />}
    </Container>
  );
};

AnimatedImage.defaultProps = {
  isImgAnimated: false,
};

AnimatedImage.propTypes = {
  title: PropTypes.string.isRequired,
  coordinates: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
    isImgAnimated: PropTypes.bool,
  }).isRequired,
  imageSrc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isImgAnimated: PropTypes.bool,
  loadImageHelper: PropTypes.func.isRequired,
  soundBees: PropTypes.shape({
    setVolume: PropTypes.func,
  }).isRequired,
  soundBird: PropTypes.shape({
    setVolume: PropTypes.func,
  }).isRequired,
};

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${({ coordinatesDesktop }) => coordinatesDesktop};
  img {
    width: 100%;
    opacity: ${({ isAnimated }) => (isAnimated ? 0 : 1)};
    position: ${({ isAnimated }) => (isAnimated ? 'absolute' : 'static')};
    top: 0;
  }
  ${media.mobile`
    ${({ coordinatesMobile }) => coordinatesMobile};
  `}
`;

export default AnimatedImage;
