import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Email from '../assets/images/email.png';
import media from '../utils/media';

const Footer = (props) => {
  const {
    setContactFormActive,
    isContactFormActive,
    isMenuActive,
    isLensInitialActive,
    setLensActive,
    isPreloaderActive,
    isInitialized,
  } = props;
  return (
    <Container
      isActive={isMenuActive}
      isLensInitialActive={isLensInitialActive}
      isPreloaderActive={isPreloaderActive}
      isInitialized={isInitialized}
    >
      <CopyRight isActive={isMenuActive}>
        <Description>
          Created by
        </Description>
        <CopyRightName>
          mind-blown.ca
        </CopyRightName>
      </CopyRight>
      <ContactContainer
        onClick={() => {
          setContactFormActive(!isContactFormActive);
          setLensActive(false);
        }}
      >
        <img src={Email} alt="Email Logo" />
        <button type="submit">
          CONTACT ME
        </button>
      </ContactContainer>
    </Container>
  );
};

Footer.propTypes = {
  setContactFormActive: propTypes.func.isRequired,
  isContactFormActive: propTypes.bool.isRequired,
  isMenuActive: propTypes.bool.isRequired,
  isLensInitialActive: propTypes.bool.isRequired,
  setLensActive: propTypes.func.isRequired,
  isPreloaderActive: propTypes.bool.isRequired,
  isInitialized: propTypes.bool.isRequired,
};

export default Footer;

const Container = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px 34px;
  position: relative;
  z-index: ${({ isInitialized }) => (isInitialized ? 3100 : 100)};
  opacity: ${({ isInitialized }) => (isInitialized ? 1 : 0)};
  transition: all 1s;
  ${media.tablet`
    display: none;
  `}
  ${media.mobile`
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
    @media (orientation: landscape) {
      display: none;
    } 
  `}
`;

const CopyRight = styled.div`
  line-height: 0.8;
  ${media.tablet`
    display: none;
  `};
  ${media.mobile`
    display: block;
    position: fixed;
    z-index: 200;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    @media (orientation: landscape) {
      display: none;
    }
    @media (max-height: 540px) {
      display: none;
    }
  `}
`;

const Description = styled.p`
  text-transform: uppercase;
  ${media.mobile`
    margin-bottom: 7px;
    font-size: 9px;
  `}
`;
const CopyRightName = styled.p`
  font-family: "Source Sans Pro - Semibold", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  ${media.mobile`
    font-size: 15px;
  `}
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 200;
  cursor: pointer;
  img {
     align-self: flex-end;
  }
  button {
    font-family: "Source Sans Pro - Semibold", sans-serif;
    font-size: 18px;
  }
  ${media.mobile`
    display: none;
  `}
`;
