import ants from '../assets/images/static_images/ants_marching.png';
import bees from './beesImages';
import bird from './birdImages';
import building from '../assets/images/static_images/sand_and_buildings.png';
import butterfly from './butterflyImages';
import cloud from '../assets/images/static_images/cloud.png';
import grass from '../assets/images/static_images/grass.png';
import flocks from './flocksImages';
import mainLogo from '../assets/images/main_logo.png';
import model from '../assets/images/static_images/model.png';
import sand from '../assets/images/static_images/sand.png';
import scull from '../assets/images/static_images/lion_skull_crystals.png';
import shadow from '../assets/images/static_images/shadow.png';
import ship from '../assets/images/static_images/steampunk_ship.png';
import sky from './auraImages';
import skyBackground from '../assets/images/static_images/sky_background.png';
import snail from '../assets/images/static_images/snail.png';
import vines from './vinesImages';
import water from '../assets/images/static_images/water.png';
import waterfall from './waterfallImages';
import waterfallTree from '../assets/images/static_images/waterfall_tree.png';

export default {
  ants,
  bees,
  bird,
  building,
  butterfly,
  cloud,
  grass,
  flocks,
  mainLogo,
  model,
  sand,
  scull,
  shadow,
  ship,
  sky,
  skyBackground,
  snail,
  vines,
  water,
  waterfall,
  waterfallTree,
};
