import 'destyle.css';
import { createGlobalStyle } from 'styled-components';
import back from '../assets/images/main_background_1.png';


const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
    min-height: 100%;
  }
  
  body {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #000;
    line-height: 1;
    background:
    url(${back}), 
    linear-gradient(-231deg, #ddd49b 0%, rgba(203, 197, 179, 0) 43%, rgba(178, 176, 210, 0) 100%),
    #a0c2c9;
    background-blend-mode: luminosity, normal;
    background-size: 100% 100%;
    box-shadow: inset 0 0 208px 43px rgba(0, 0, 0, 0.35);
  }
  
  #root {
    height: 100%;
  }
  
  ul, ol, li {
    list-style-type: none;
  }
`;

export default GlobalStyles;
