import React, {Component} from 'react';
import styled from 'styled-components';
import GlobalStyles from './styles';
import FontsStyles from './styles/fonts';
import MainPage from './containers/MainPage';

const apiUrl = window.getConfig.url;

class App extends Component {
  constructor() {
    super();
    this.state = {
      data: {}
    }
  }

  componentDidMount() {
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => this.setState({ data }));
  }

  render() {
    return (
      <Container>
        <FontsStyles/>
        <GlobalStyles/>
        <MainPage photoListFetched={this.state.data} />
      </Container>
    );
  };
}

export default App;

const Container = styled.div`
  height: 100%;
`;
