const vinesImages = [];
let startImageNum = '000';
for (let i = 14; i < 230; i += 1) {
  if (i >= 10 && i < 100) {
    startImageNum = '000';
  } else if (i >= 100) {
    startImageNum = '00';
  }
  vinesImages.push(`/images/Vines/vines_${startImageNum}${i}-min.png`);
}

export default vinesImages;
