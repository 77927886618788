import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import media from '../utils/media';

const StaticImage = (props) => {
  const {
    title,
    coordinates,
    imageSrc,
    children,
    dataDepth,
    className,
    loadImageHelper,
  } = props;
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <Container
      coordinatesDesktop={coordinates.desktop}
      coordinatesMobile={coordinates.mobile}
      coordinatesTablet={coordinates.tablet}
    >
      {children}
      <img
        className={className}
        data-depth={dataDepth}
        src={imageSrc}
        alt={title}
        onLoad={loadImageHelper}
      />
    </Container>
  );
};

StaticImage.defaultProps = {
  children: null,
  dataDepth: 0,
  className: '',
};

StaticImage.propTypes = {
  title: propTypes.string.isRequired,
  coordinates: propTypes.shape({
    desktop: propTypes.string,
    mobile: propTypes.string,
    tablet: propTypes.string,
    isImgAnimated: propTypes.bool,
  }).isRequired,
  imageSrc: propTypes.string.isRequired,
  children: propTypes.arrayOf(propTypes.element),
  dataDepth: propTypes.number,
  className: propTypes.string,
  loadImageHelper: propTypes.func.isRequired,
};

const Container = styled.div`
  position: absolute;
  transition: width .5s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${({ coordinatesDesktop }) => coordinatesDesktop};
  ${media.tablet`
    ${({ coordinatesTablet }) => coordinatesTablet};
  `}
  ${media.mobile`
    ${({ coordinatesMobile }) => coordinatesMobile};
  `}
  img {
    width: 100%;
  }
`;

export default StaticImage;
