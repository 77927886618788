import React from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Arrow } from './Icons';
import media from '../utils/media';

const Pagination = (props) => {
  const {
    setCurrentItem,
    currentItem,
    items,
    setAnimated,
  } = props;
  const setItem = (newValue) => {
    if (newValue >= 0 && newValue < items.length) {
      setCurrentItem(newValue);
    }
  };
  return (
    <Container>
      <ArrowContainer>
        <ArrowUp
          isActive={currentItem > 0}
          onClick={() => {
            setItem(currentItem - 1);
            setAnimated(true);
          }}
        >
          <Arrow />
        </ArrowUp>
        <ArrowDown
          isActive={!(currentItem >= (items.length - 1))}
          onClick={() => {
            setItem(currentItem + 1);
            setAnimated(true);
          }}
        >
          <Arrow />
        </ArrowDown>
      </ArrowContainer>
      <Dots>
        {items.map((item, i) => (
          <Dot
            key={item.name + i}
            isActive={i === currentItem}
            onClick={() => setCurrentItem(i)}
          />
        ))}
      </Dots>
    </Container>
  );
};

Pagination.propTypes = {
  setCurrentItem: propTypes.func.isRequired,
  currentItem: propTypes.number.isRequired,
  items: propTypes.arrayOf(propTypes.object).isRequired,
  setAnimated: propTypes.func.isRequired,
};

export default Pagination;

const Container = styled.div`
  position: absolute;
  right: 12%;
  top: 22%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  ${media.tablet`
    position: static;
    flex-direction: column-reverse;
    margin-top: 20px;
    @media (max-height: 360px) {
      margin-top: 0;
    }
  `}
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;  
  ${media.tablet`
    align-self: stretch;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
  `}
`;

const arrow = css`
  width: 85px;
  height: 85px;
  border: 4px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ isActive }) => (isActive ? '1' : '0.5')};
  ${media.tablet`
    width: 75px;
    height: 75px;
    border: 2px solid #000;
    path {
      stroke: #000;
    }
    @media (max-height: 360px) {
      width: 45px;
      height: 45px;
      svg {
        width: 15px;
        height: 9px;
      }
    }
  `}
`;

const ArrowUp = styled.button`
  ${arrow};
  transform: rotate(180deg);
  margin-bottom: 25px;
  ${media.tablet`
    margin-bottom: 0;
    transform: rotate(90deg);
    svg {
      transform: translateY(3px);
      opacity: 1;
    }
  `}
`;

const ArrowDown = styled.button`
  ${arrow};
  ${media.tablet`
    transform: rotate(270deg);
    svg {
      transform: translateY(3px);
    }
  `}
`;

const Dots = styled.nav`
  margin-top: 45px;
  ${media.tablet`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
    flex-wrap: wrap;
    min-height: 80px;
    @media (max-height: 360px) {
      margin-bottom: 0;
      min-height: auto;
    }
  `};
  ${media.mobile`
    flex-wrap: nowrap;
    margin-bottom: 0;
    min-height: 50px;
    @media (orientation: landscape) {
      flex-wrap: wrap;
    }
  `}
`;

const Dot = styled.li`
  padding: 10px;
  cursor: pointer;
  margin-bottom: 11px;
  @media (max-height: 360px) {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    background-color: #fff;
    border-radius: 50%;
    transform: scale(${({ isActive }) => (isActive ? '1.5' : '1')});
    box-shadow: ${({ isActive }) => (isActive ? '0px 0px 1px 6px rgba(255, 255, 255, .3)' : 'unset')};
    ${media.tablet`
      background-color: #000;
      box-shadow: ${({ isActive }) => (isActive ? '0px 0px 1px 6px rgba(0, 0, 0, .3)' : 'unset')};
    `}
  }
`;
