import React from 'react';
import SemiBold from '../assets/fonts/SourceSansPro-SemiBold.ttf';
import Regular from '../assets/fonts/SourceSansPro-Regular.ttf';

const FontStyles = () => (
  <style>
    {`
      @font-face {
        font-family: 'Source Sans Pro';
        src: url(${Regular}) format('truetype');
        font-weight: 400;
        font-style: normal;
      }
          
      @font-face {
        font-family: 'Source Sans Pro - SemiBold';
        src: url(${SemiBold}) format('truetype');
        font-weight: 400;
        font-style: normal;
      }
    `}
  </style>
);

export default FontStyles;
