import React, { useState } from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Welcome from './Welcome';
import Portfolio from './Portfolio';
import ContactForm from '../components/ContactForm';
import media from '../utils/media';
import LensOverlay from '../components/LensOverlay';
import Preloader from '../components/Preloader';
import Sound from '../utils/sound';

const DURATION = 3000;
const FADEINDURATION = 8000;
// eslint-disable-next-line no-unused-vars
const mainSound = new Sound(`${process.env.PUBLIC_URL}/sound/mysterious_fortune.ogg`, true, -0.5);

const MainPage = (props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentType, setCurrentType] = useState(0);
  const [currentItem, setCurrentItem] = useState(0);
  const [isAnimated, setAnimate] = useState(false);
  const [isMenuActive, setMenuActive] = useState(false);
  const [isLensInitialActive, setLensInitialActive] = useState(true);
  const [isLensActive, setLensActive] = useState(true);
  const [isPreloaderActive, setPreloaderActive] = useState(true);
  const [isContactFormActive, setContactFormActive] = useState(false);
  const [successMessage, setSuccessMessageActive] = useState(false);
  const [isInitialized, setInitialized] = useState(false);
  const [isLensAnimated, setLensAnimated] = useState(false);
  const [isAssetsSoundActive, setAssetsSoundActive] = useState(false);
  const [preloadCount, setPreloadCount] = useState(0);
  const [lensLoaded, setLensLoaded] = useState(false);
  const loadImageHelper = () => {
    setPreloadCount(preloadCount + 0.27855);
  };
  const screenList = [
    {
      key: 'screen1',
      content: <Welcome
        isAnimated={isAnimated}
        duration={DURATION}
        isPreloaderActive={isPreloaderActive}
        isLensInitialActive={isLensInitialActive}
        isLensActive={isLensActive}
        setPreloaderActive={setPreloaderActive}
        isAssetsSoundActive={isAssetsSoundActive}
        loadImageHelper={loadImageHelper}
      />,
    },
    {
      key: 'screen2',
      content: <Portfolio
        isAnimated={isAnimated}
        duration={DURATION}
        currentType={currentType}
        setCurrentType={setCurrentType}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        photoListFetched={props.photoListFetched}
      />,
    },
  ];
  const nextPage = () => {
    if (isAnimated) return;
    setAnimate(true);
    setLensActive(false);
    setAssetsSoundActive(false);
    setTimeout(() => {
      setCurrentPage(currentPage + 1);
      setAnimate(false);
    }, DURATION);
  };
  const previousPage = () => {
    if (isAnimated) return;
    setAnimate(true);
    setLensAnimated(true);
    setAssetsSoundActive(true);
    setTimeout(() => {
      setCurrentPage(currentPage - 1);
      setAnimate(false);
    }, DURATION);
  };
  const wheelHelper = (e) => {
    const isScrolledDown = !isContactFormActive
      && e.deltaY > 0
      && (currentPage !== screenList.length - 1)
      && isInitialized;
    if (isScrolledDown) {
      nextPage();
    } else if (!isContactFormActive && e.deltaY < 0 && currentPage) {
      previousPage();
    }
  };
  const swipeHelper = (e) => {
    const isSwipedUp = e.dir === 'Up'
      && (currentPage !== screenList.length - 1)
      && !isContactFormActive
      && isInitialized;
    if (isSwipedUp) {
      nextPage();
    } else if (e.dir === 'Down' && !isContactFormActive && currentPage) {
      previousPage();
    }
  };
  const handlers = useSwipeable({ onSwiped: eventData => swipeHelper(eventData), delta: 20 });
  const startHandler = () => {
    setInitialized(true);
    setAssetsSoundActive(true);
    setTimeout(() => {
      setLensInitialActive(false);
    }, FADEINDURATION);
  };
  if (successMessage) {
    setTimeout(() => setSuccessMessageActive(!successMessage), 5000);
  }
  return (
    <MainPageContainer
      onWheel={wheelHelper}
      isFixed={isMenuActive}
      {...handlers}
    >
      <Header
        isMenuActive={isMenuActive}
        setMenuActive={setMenuActive}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        currentType={currentType}
        setCurrentType={setCurrentType}
        isLensActive={isLensActive}
        setLensActive={setLensActive}
        isLensInitialActive={isLensInitialActive}
        isContactFormActive={isContactFormActive}
        setContactFormActive={setContactFormActive}
        successMessage={successMessage}
        setSuccessMessageActive={setSuccessMessageActive}
        isPreloaderActive={isPreloaderActive}
        isInitialized={isInitialized}
        setCurrentItem={setCurrentItem}
        isAnimated={isAnimated}
      />
      <LensOverlay
        isLensInitialActive={isLensInitialActive}
        setLensInitialActive={setLensInitialActive}
        isLensActive={isLensActive}
        isPreloaderActive={isPreloaderActive}
        currentPage={currentPage}
        isInitialized={isInitialized}
        isLensAnimated={isLensAnimated}
        lensLoaded={lensLoaded}
        setLensLoaded={setLensLoaded}
      />
      <Preloader
        isActive={isPreloaderActive}
        setPreloaderActive={setPreloaderActive}
        isLensInitialActive={isLensInitialActive}
        setLensInitialActive={setLensInitialActive}
        preloadCount={preloadCount}
        lensLoaded={lensLoaded}
      />
      <ContactForm
        setContactFormActive={setContactFormActive}
        isContactFormActive={isContactFormActive}
        successMessage={successMessage}
        setSuccessMessageActive={setSuccessMessageActive}
        isMenuActive={isMenuActive}
        setMenuActive={setMenuActive}
        isMobileActive={false}
      />
      {screenList[currentPage].content}
      <SuccessMessage
        isActive={successMessage}
      >
        Your data send successfully!
      </SuccessMessage>
      <ScrollMessage isActive={!currentPage && !isPreloaderActive && isInitialized}>
        SCROLL TO EXPLORE
      </ScrollMessage>
      <StartButton
        isActive={!currentPage && !isPreloaderActive && !isInitialized}
        onClick={startHandler}
      >
        BEGIN
      </StartButton>
      <Footer
        setContactFormActive={setContactFormActive}
        isContactFormActive={isContactFormActive}
        isMenuActive={isMenuActive}
        setMenuActive={setMenuActive}
        isLensInitialActive={isLensInitialActive}
        setLensActive={setLensActive}
        isPreloaderActive={isPreloaderActive}
        isInitialized={isInitialized}
      />
    </MainPageContainer>
  );
};

export default MainPage;

const MainPageContainer = styled.div`
  height: 100%;  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  ${media.mobile`
    min-height: 100%;
    height: auto;
    width: 100%;
    display: flex;
    position: ${({ isFixed }) => (isFixed ? 'fixed' : 'relative')};
  `}
`;

const SuccessMessage = styled.div`
  background-color: rgba(255, 255, 255, .8);
  font-size: 25px;
  padding: 10px 20px;
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  transition: opacity 1s;
`;

const ScrollMessage = styled.div`
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity 1s;
  align-self: center;
  width: 228px;
  text-align: center;
  font-size: 18px;
  height: 41px;
  line-height: 41px;
  background-color: rgba(0, 0, 0, .15);
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${media.tablet`
    display: none;
    @media (orientation: landscape) {
      display: block;
    }
  `}
`;

const StartButton = styled.button`
  opacity: ${({ isActive }) => (isActive ? 0.9 : 0)}; 
  cursor: pointer;
  width: 100px;
  height: 25px;
  text-align: center;
  font-size: 18px;
  line-height: 25px;
  background-color: #fff;
  color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  //transform: translate(-45%, -50%);
  transform: translate(-39%, -50%);
  transition: opacity .3s;
  z-index: ${({ isActive }) => (isActive ? 2000 : -20)};
  
  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
  
  ${media.tablet`
    width: 100px;
    height: 25px;
    line-height: 25px;
    bottom: 18%;
    top: auto;
    transform: translate(-34%, -50%);
    @media (orientation: landscape) {
      bottom: auto;
      top: 50%;
      transform: translate(-44%, -50%);
    }
  `}
  ${media.mobile`
    transform: translate(-48%, -50%);
    @media (orientation: landscape) {
      bottom: auto;
      top: 50%;
      transform: translate(-34%, -50%);
    }
  `};
`;
