import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { LogoHeader } from './Icons';
import Menu from './Menu';
import media from '../utils/media';

const Header = (props) => {
  const {
    isMenuActive,
    setMenuActive,
    currentPage,
    setCurrentPage,
    currentType,
    setCurrentType,
    isLensActive,
    setLensActive,
    isLensInitialActive,
    isContactFormActive,
    setContactFormActive,
    successMessage,
    setSuccessMessageActive,
    isPreloaderActive,
    isInitialized,
    setCurrentItem,
    isAnimated,
  } = props;
  return (
    <Container
      currentPage={currentPage}
      isLensInitialActive={isLensInitialActive}
      isMenuActive={isMenuActive}
      isPreloaderActive={isPreloaderActive}
      isInitialized={isInitialized}
    >
      <Menu
        isMenuActive={isMenuActive}
        setMenuActive={setMenuActive}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        currentType={currentType}
        setCurrentType={setCurrentType}
        isContactFormActive={isContactFormActive}
        setContactFormActive={setContactFormActive}
        successMessage={successMessage}
        setSuccessMessageActive={setSuccessMessageActive}
        setLensActive={setLensActive}
        isLensInitialActive={isLensInitialActive}
        setCurrentItem={setCurrentItem}
      />
      <LogoContainer isActive={isMenuActive}>
        <LogoHeader />
      </LogoContainer>
      <LensContainer
        currentPage={currentPage}
        isContactFormActive={isContactFormActive}
      >
        <Lens
          isActive={isLensActive}
          onClick={() => {
            if (isAnimated) return;
            setLensActive(!isLensActive);
          }}
        />
      </LensContainer>
    </Container>
  );
};

Header.propTypes = {
  isMenuActive: propTypes.bool.isRequired,
  setMenuActive: propTypes.func.isRequired,
  setCurrentPage: propTypes.func.isRequired,
  setCurrentType: propTypes.func.isRequired,
  currentPage: propTypes.number.isRequired,
  currentType: propTypes.number.isRequired,
  isLensActive: propTypes.bool.isRequired,
  setLensActive: propTypes.func.isRequired,
  isLensInitialActive: propTypes.bool.isRequired,
  isContactFormActive: propTypes.bool.isRequired,
  setContactFormActive: propTypes.func.isRequired,
  successMessage: propTypes.bool.isRequired,
  setSuccessMessageActive: propTypes.func.isRequired,
  isPreloaderActive: propTypes.bool.isRequired,
  isInitialized: propTypes.bool.isRequired,
  setCurrentItem: propTypes.func.isRequired,
  isAnimated: propTypes.bool.isRequired,
};

export default Header;

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 56px 0 32px;
  position: relative;
  z-index: ${({ isInitialized }) => (!isInitialized ? 100 : 3100)};
  opacity: ${({ isPreloaderActive, isInitialized }) => (isPreloaderActive || !isInitialized ? 0 : 1)};
  transition: all 1s;
  ${media.tablet`
    padding: 10px 14px 20px;
    justify-content: ${({ isMenuActive, currentPage }) => (isMenuActive || currentPage ? 'center' : 'space-between')};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  `};
  ${media.mobile`
    position: static;
    @media (orientation: landscape) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  `}
`;

const LogoContainer = styled.div`
  position: relative;
  z-index: 200;
  
  ${media.tablet`
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    flex-basis: 50%;
    padding: 5px 0;
    background-color: #fff;
    border-radius: 73px;
    text-align: center;
    height: 45px;
    svg {
      width: 100%;
      height: 100%;
    }
  `};
  
  ${media.mobile`
    display: block;
    @media (orientation: landscape) {
      flex-basis: 35%;
      display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    }
  `};
    @media (orientation: landscape) and (max-height: 200px) {
      display: none;
    }
`;

const LensContainer = styled.div`
  display: ${({ currentPage, isContactFormActive }) => (currentPage || isContactFormActive ? 'none' : 'block')};
  cursor: pointer;
  position: relative;
  z-index: 100;
  
  ${media.mobile`
    z-index: -10;
  `}
`;

const Lens = styled.div`
  width: 78px;
  height: 78px;
  border: ${({ isActive }) => (isActive ? '12px' : '2px')} solid ${({ isActive }) => (isActive ? '#000' : '#fff')};
  border-radius: 50%;
  transition: border 1s;
  ${media.tablet`
    width: 45px;
    height: 45px;
  `}
`;
