import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import media from '../utils/media';

const Preloader = (props) => {
  const {
    isActive,
    setPreloaderActive,
    isLensInitialActive,
    preloadCount,
    lensLoaded,
  } = props;
  const count = Math.round(preloadCount);
  useEffect(() => {
    if (isActive && (count === 100)) {
      setPreloaderActive(false);
    }
  }, [
    setPreloaderActive,
    isActive,
    count,
  ]);
  return (
    <PreloaderWrapper
      isActive={isActive}
      isLensInitialActive={isLensInitialActive}
    >
      <Content
        lensLoaded={lensLoaded}
      >
        {count}
        %
      </Content>
    </PreloaderWrapper>
  );
};

Preloader.propTypes = {
  isActive: propTypes.bool.isRequired,
  isLensInitialActive: propTypes.bool.isRequired,
  setPreloaderActive: propTypes.func.isRequired,
  preloadCount: propTypes.number.isRequired,
  lensLoaded: propTypes.bool.isRequired,
};

const PreloaderWrapper = styled.div`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;
  justify-content: center;
  align-items: center;
  ${media.tablet`
    align-items: flex-end;
    bottom: 18%;
    @media (orientation: landscape) {
      bottom: 0;
      align-items: center;
      margin-bottom: 0;
    }
  `}
`;

const Content = styled.div`
  transition: opacity 1s;
  font-size: 60px;
  color: ${({ lensLoaded }) => (lensLoaded ? '#fff' : '#000')};
  transition: color .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ lensLoaded }) => (lensLoaded ? 'transform: translateX(14%)' : '')};
  ${media.mobile`
    font-size: 40px;
  `};
  ${media.tablet`
    font-size: 50px;
    @media (orientation: landscape) {
      transform: translateX(14%);
    }
  `}
`;

export default Preloader;
