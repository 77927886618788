import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../utils/media';

const Navigation = (props) => {
  const {
    portfolio,
    currentType,
    setCurrentType,
    setCurrentItem,
    setAnimated,
  } = props;
  const setType = (newValue) => {
    setCurrentItem(0);
    setCurrentType(newValue);
  };
  return (
    <Container>
      {portfolio.map((item, i) => (
        <Item
          key={item.type}
          isActive={i === currentType}
          onClick={() => {
            setType(i);
            setAnimated(true);
          }}
        >
          {item.type}
        </Item>
      ))}
    </Container>
  );
};

Navigation.propTypes = {
  portfolio: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentType: PropTypes.number.isRequired,
  setCurrentType: PropTypes.func.isRequired,
  setCurrentItem: PropTypes.func.isRequired,
  setAnimated: PropTypes.func.isRequired,
};

export default Navigation;

const Container = styled.nav`
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  ${media.tablet`
    position: static;
    transform: none;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    flex-direction: row;
    @media (orientation: landscape) {
      flex-direction: column;
    }
  `};
`;

const Item = styled.li`
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  margin-bottom: 54px;
  padding-left: 61px;
  position: relative;
  font-size: 18px;
  ${media.tablet`
    text-transform: lowercase;
    letter-spacing: 0px;
    padding-left: 0;
    margin-bottom: 0;
    margin-right: 1px;
    text-align: center;
    background-color: ${({ isActive }) => (isActive ? 'rgba(0, 0, 0, .5)' : 'rgba(0, 0, 0, .35)')};
    width: 100%;
    min-height: 50px;
    line-height: 50px;
    color: ${({ isActive }) => (isActive ? '#fff' : 'rgba(255, 255, 255, .75)')};
    @media (orientation: landscape) {
      margin-bottom: 5px;
      width: 100%;
    }
  `};

  &:last-child {
    margin-bottom: 0;
  }
  
  &::before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 46px;
    top: 2px;
    transform: translateX(-100%);
    background-color: ${({ isActive }) => (isActive ? '#000' : 'transparent')};
    border-radius: 50%;
    border: 2px solid #000;
    
    ${media.tablet`
      content: none;
    `}
  }
`;
