import React, { useEffect, useState, useRef } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Parallax from 'parallax-js';
import { isMobile } from 'is-mobile';
import media from '../utils/media';
import LensBackground from '../assets/images/lens_overlay_4.png';
import LensBackgroundTransparent from '../assets/images/EllipseBigTransparent.svg';

const FADEINDURATION = 8000;

const LensOverlay = (props) => {
  const {
    isLensInitialActive,
    setLensInitialActive,
    isLensActive,
    isPreloaderActive,
    currentPage,
    isInitialized,
    isLensAnimated,
    lensLoaded,
    setLensLoaded,
  } = props;
  const lens = useRef(null);
  const lensTransparent = useRef(null);
  const [isLensBackgroundLoaded, setLensBackgroundLoaded] = useState(false);
  const [isLandscape, setLandscape] = useState(false);
  const isLensSceneAnimated = !isMobile() && !isPreloaderActive && isInitialized;
  const onLoadLensHelper = () => {
    if (!isPreloaderActive) {
      lens.current.style.opacity = 1;
      lensTransparent.current.style.opacity = 0;
    }
    setLensBackgroundLoaded(true);
  };
  const onLoadLensTransparentHelper = () => {
    setLensLoaded(true);
    if (isPreloaderActive && !isLensBackgroundLoaded) {
      lens.current.style.opacity = 0;
      lensTransparent.current.style.opacity = 1;
    }
  };
  useEffect(() => {
    const scene = document.getElementById('lensScene');
    if (!isPreloaderActive) {
      lens.current.style.opacity = 1;
      lensTransparent.current.style.opacity = 0;
    }
    if (!isMobile() && !isLensInitialActive && isInitialized) {
      // eslint-disable-next-line no-unused-vars
      const desktopLensParallaxInstance = new Parallax(scene, {
        limitY: 0,
        scalarX: 2,
        invertX: false,
      });
    }
    if (isMobile({ tablet: true }) && isInitialized && !isLensInitialActive) {
      // eslint-disable-next-line no-unused-vars
      const mobileLensParallaxInstance = new Parallax(scene, {
        scalarX: 3,
        scalarY: 3,
        limitY: 7,
        limitX: 15,
        invertX: false,
        invertY: false,
      });
    }
    window.addEventListener('orientationchange', () => {
      if (window.orientation) {
        setLandscape(true);
      } else {
        setLandscape(false);
      }
    });
  }, [
    isPreloaderActive,
    isLensInitialActive,
    setLensInitialActive,
    isInitialized,
    lensLoaded,
  ]);
  return (
    <LensScene
      id="lensScene"
      isLensInitialActive={isLensInitialActive}
      isLensActive={isLensActive}
      isActive={!currentPage}
    >
      <Container
        data-depth="15"
      >
        <BackgroundContainer
          isAnimated={isLensSceneAnimated}
          isLensAnimated={isLensAnimated}
          isLensActive={isLensActive}
          isSvgActive={!lensLoaded}
          isLandscape={isLandscape}
        >
          <WhiteBG1
            isInitialized={isInitialized}
            isPreloaderActive={isPreloaderActive}
            lensLoaded={lensLoaded}
            isAnimated={isLensSceneAnimated}
          />
          <Cont>
            <WhiteBG2
              isInitialized={isInitialized}
              isPreloaderActive={isPreloaderActive}
              lensLoaded={lensLoaded}
            />
            <Lens
              isLensInitialActive={isLensInitialActive}
              isLensActive={isLensActive}
              isActive
              isAnimated={isLensSceneAnimated}
              isInitialized={isInitialized}
              isLensAnimated={isLensAnimated}
              isPreloaderActive={isPreloaderActive}
              lensLoaded={lensLoaded}
              isLandscape={isLandscape}
            >
              <img onLoad={onLoadLensHelper} ref={lens} src={LensBackground} alt="lens" draggable={false} />
              <img onLoad={onLoadLensTransparentHelper} ref={lensTransparent} src={LensBackgroundTransparent} alt="lens" draggable={false} />
            </Lens>
            <WhiteBG3
              isInitialized={isInitialized}
              isPreloaderActive={isPreloaderActive}
              lensLoaded={lensLoaded}
            />
          </Cont>
          <WhiteBG4
            isInitialized={isInitialized}
            isPreloaderActive={isPreloaderActive}
            lensLoaded={lensLoaded}
          />
        </BackgroundContainer>
      </Container>
    </LensScene>
  );
};

LensOverlay.propTypes = {
  isLensInitialActive: propTypes.bool.isRequired,
  setLensInitialActive: propTypes.func.isRequired,
  isLensActive: propTypes.bool.isRequired,
  isPreloaderActive: propTypes.bool.isRequired,
  currentPage: propTypes.number.isRequired,
  isInitialized: propTypes.bool.isRequired,
  isLensAnimated: propTypes.bool.isRequired,
  lensLoaded: propTypes.bool.isRequired,
  setLensLoaded: propTypes.func.isRequired,
};

const LensScene = styled.div`
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1s;
  opacity: ${({ isLensActive }) => (isLensActive ? 1 : 0)};
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  ${media.tablet`
    background: linear-gradient(rgb(29,48,55), rgba(29,48,55,0));
    background-repeat: no-repeat;
    background-size: 100% 50%;
    background-position: 0 0;
    
  `};
`;

const Container = styled.div`
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  ${media.mobile`
     width: 100%;
  `}
`;

const BackgroundContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${({ isAnimated, isLensAnimated, isLandscape }) => (isAnimated && !isLensAnimated && !isLandscape ? `moveBack ${FADEINDURATION}ms forwards` : 'none')};
  transform: scale(${({ isLensActive }) => (isLensActive ? 1 : 2)});
  transition: transform 1s;
  will-change: transform, top, left;
  ${({ isLandscape }) => (isLandscape ? 'top: -33.33333%' : '')};
  ${({ isLandscape }) => (isLandscape ? 'left: -33.33333%' : '')};
  
  ${({ isLensAnimated }) => (!isLensAnimated ? '' : 'top: -33.33333%')};
  ${({ isLensAnimated }) => (!isLensAnimated ? '' : 'left: -33.33333%')};
  
  @keyframes moveBack {
    0% {
      top: 0;
      left: 0;
    }
    100% {
      top: -33.33333%;
      left: -33.33333%;
    }
  }
  ${media.tablet`
    top: -50vh;
    left: -33.33333vw;
    animation: none;
    @media (orientation: landscape) {
      left: -100vw;
      top: -33.3333333vh;
      animation: ${({ isAnimated, isLensAnimated, isLandscape }) => (isAnimated && !isLensAnimated && !isLandscape ? `moveBack ${FADEINDURATION}ms forwards` : 'none')};
      ${({ isLandscape }) => (isLandscape ? 'top: -33.3333333vh' : '')};
      ${({ isLandscape }) => (isLandscape ? 'left: -100vw' : '')};
    }
  `};
  ${media.mobile`
     left: -103vw;
     top: -50vh;
     @media (orientation: landscape) {
      left: -100vw;
      top: -33.3333333vh;
     }
  `};
`;

const Cont = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
`;

const WhiteBG1 = styled.div`
  width: 100%;
  height: 33.33333vh;
  background-color: rgba(255, 255, 255, ${({ isPreloaderActive }) => (isPreloaderActive ? '1' : '.9')});
  transition: background-color .5s;
  ${media.tablet`
  height: 110vh;
  animation: none;
  animation-iteration-count: ${({ isLensAnimated }) => (isLensAnimated ? 0 : 1)};
  @media (orientation: landscape) {
    animation: none;
    height: 33.33333vh;
  }
  `};
  ${media.mobile`
    height: 100vh;
    
    @media (orientation: landscape) {
      height: 33.33333vh;
     }
  `};
  @media (max-width: 787px) and (min-height: 650px) {
    height: 110vh;
  }
  @keyframes tabletMove {
    from {
      height: 100vh;
    }
    to {
      height: 40vh;
    }
  }
`;

const WhiteBG2 = styled.div`
  width: 33.3333333vw;
  background-color: rgba(255, 255, 255, ${({ isPreloaderActive }) => (isPreloaderActive ? '1' : '.9')});
  transition: background-color .5s;
  ${media.tablet`
    @media (orientation: landscape) {
      width: 100vw;
    }
  `};
  ${media.mobile`
    width: 100vw;
  `}
`;

const WhiteBG3 = styled.div`
  width: 33.3333333vw;
  background-color: rgba(255, 255, 255, ${({ isPreloaderActive }) => (isPreloaderActive ? '1' : '.9')});
  transition: background-color .5s;
  ${media.mobile`
    width: 100vw;
  `}
`;

const WhiteBG4 = styled.div`
  width: 100%;
  height: 33.33333vh;
  background-color: rgba(255, 255, 255, ${({ isPreloaderActive }) => (isPreloaderActive ? '1' : '.9')});
  transition: background-color .5s;
  ${media.mobile`
    height: 70vh;
  `};
`;

const Lens = styled.div`
  ${({ isAnimated }) => (isAnimated ? '' : 'width: 33.3333vw')};
  ${({ isAnimated }) => (isAnimated ? '' : 'height: 33.3333vh')};
  ${({ isLensAnimated }) => (!isLensAnimated ? '' : 'width: 100vw')};
  ${({ isLensAnimated }) => (!isLensAnimated ? '' : 'height: 100vh')};
  will-change: width, height;
  background-color: ${({ lensLoaded }) => (lensLoaded ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 1)')};
 
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity 1s;
  animation: ${({ isAnimated, isLensAnimated, isLandscape }) => (isAnimated && !isLensAnimated && !isLandscape ? `sizing ${FADEINDURATION}ms forwards` : 'none')};
  animation-iteration-count: ${({ isLensAnimated }) => (isLensAnimated ? 0 : 1)};
  position: relative;
  ${({ isLandscape }) => (isLandscape ? 'width: 100vw' : '')};
  ${({ isLandscape }) => (isLandscape ? 'height: 100vh' : '')};
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  img:first-child {
    opacity: 1;
  }
  @keyframes sizing {
    0% {
      width:  33.33333vw;
      height: 33.33333vh;
    }
    100% {
      width: 100vw;
      height: 100vh;
    }
  }
  
  ${media.tablet`
    width: 100vw;
    height: 40vh;
    animation: none;
    animation-iteration-count: ${({ isLensAnimated }) => (isLensAnimated ? 0 : 1)};
    @media (orientation: landscape) {
      animation: ${({ isAnimated, isLensAnimated, isLandscape }) => (isAnimated && !isLensAnimated && !isLandscape ? `tabletSizing ${FADEINDURATION}ms forwards` : 'none')};
      width: 100vw;
      height: 100vh;
      ${({ isLandscape }) => (isLandscape ? 'height: 100vh' : '')};
    }
    @keyframes tabletSizing {
    0% {
      height: 40vh;
    }
    100% {
      height: 100vh;
    }
  }
  `};
  ${media.mobile`
     width: 100vw;
     height: 50vh;
     
     @media (orientation: landscape) {
      height: 100vh;
     }
  `}
  @media (max-width: 787px) and (min-height: 650px) {
    height: 40vh;
  }
`;

export default LensOverlay;
