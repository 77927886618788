import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import media from '../utils/media';

const MenuNavigation = (props) => {
  const {
    setMenuActive,
    currentPage,
    setCurrentPage,
    currentType,
    setCurrentType,
    setContactFormActive,
    isContactFormActive,
    setCurrentItem,
  } = props;
  const navHelper = (page, type) => {
    setCurrentPage(page);
    setCurrentType(type);
    setCurrentItem(0);
    setMenuActive(false);
    setContactFormActive(!isContactFormActive);
  };
  return (
    <Container>
      <Item
        isActive={!currentPage}
        onClick={() => navHelper(0, 0)}
      >
        Welcome
      </Item>
      <Item
        isActive={currentPage && !currentType}
        onClick={() => navHelper(1, 0)}
      >
        Photography
      </Item>
      <Item
        isActive={currentPage && currentType}
        onClick={() => navHelper(1, 1)}
      >
        Graphic design
      </Item>
    </Container>
  );
};

MenuNavigation.propTypes = {
  setMenuActive: propTypes.func.isRequired,
  setCurrentPage: propTypes.func.isRequired,
  setCurrentType: propTypes.func.isRequired,
  currentPage: propTypes.number.isRequired,
  currentType: propTypes.number.isRequired,
  setContactFormActive: propTypes.func.isRequired,
  isContactFormActive: propTypes.bool.isRequired,
  setCurrentItem: propTypes.func.isRequired,
};

export default MenuNavigation;

const Container = styled.nav`
  padding: 0 7.1px;
  ${media.tablet`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `}
  ${media.mobile`
    display: block;
    width: auto;
    @media (orientation: landscape) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  `}
  @media (orientation: landscape) and (max-height: 200px) {
    display: none;
  }
`;

const Item = styled.li`
  background-color: #fff;
  min-height: 36px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 6px;
  font-size: 16px;
  text-transform: lowercase;
  font-family: ${({ isActive }) => (isActive ? '"Source Sans Pro - Semibold", sans-serif' : 'inherit')};
  opacity: ${({ isActive }) => (isActive ? '1' : '.75')};

  &:last-child {
    margin-bottom: 0;
  }
  ${media.tablet`
    flex-basis: 33%;
    margin-bottom: 0;
  `}
  ${media.mobile`
    margin-bottom: 6px;
    flex-basis: auto;
    @media (orientation: landscape) {
      flex-basis: 33%;
      margin-bottom: 0;
    }
  `}
`;
