import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Parallax from 'parallax-js';
import imageCoordinates from '../utils/newImageCoordinates';
import PositionedImage from '../components/PositionedImage';
import imagesPath from '../utils/imagesPath';
import StaticImage from '../components/staticImage';
import Sound from '../utils/sound';

const sounds = {
  bees: `${process.env.PUBLIC_URL}/sound/BEE.ogg`,
  bird: `${process.env.PUBLIC_URL}/sound/HUMMING BIRD.ogg`,
};
const volume = -0.5;
// eslint-disable-next-line no-unused-vars
const soundBees = new Sound(sounds.bees, true, volume);
// eslint-disable-next-line no-unused-vars
const soundBird = new Sound(sounds.bird, true, volume);
const Welcome = (props) => {
  const {
    isAnimated,
    duration,
    isLensInitialActive,
    isPreloaderActive,
    isLensActive,
    loadImageHelper,
  } = props;

  React.useEffect(() => {
    const scene = document.getElementById('scene');
    const parallaxInstance = new Parallax(scene, {
      selector: '.layer',
      limitY: 0,
      scalarX: 5,
    });
    if (isLensActive) {
      parallaxInstance.disable();
    } else {
      parallaxInstance.enable();
    }
  }, [
    isLensActive,
    isPreloaderActive,
  ]);
  return (
    <Container
      id="scene"
      isAnimated={isAnimated}
      duration={duration}
      isLensInitialActive={isLensInitialActive}
      isPreloaderActive={isPreloaderActive}
    >
      <Layer
        className="layer"
        data-depth="1.4"
      >
        <StaticImage
          title="grass"
          coordinates={imageCoordinates.grass}
          imageSrc={imagesPath.grass}
          loadImageHelper={loadImageHelper}
        />
      </Layer>
      <Layer
        className="layer"
        data-depth="0.9"
      >
        <StaticImage
          title="model"
          coordinates={imageCoordinates.model}
          imageSrc={imagesPath.model}
          loadImageHelper={loadImageHelper}
        >
          <PositionedImage
            title="bees"
            isImgAnimated
            coordinates={imageCoordinates.bees}
            imageSrc={`${process.env.PUBLIC_URL}/images/bees.gif`}
            isPreloaderActive={isPreloaderActive}
            isLensInitialActive={isLensInitialActive}
            loadImageHelper={loadImageHelper}
            soundBees={soundBees}
            soundBird={soundBird}
          />
          <PositionedImage
            title="bird"
            isImgAnimated
            coordinates={imageCoordinates.bird}
            imageSrc={`${process.env.PUBLIC_URL}/images/bird.gif`}
            isPreloaderActive={isPreloaderActive}
            isLensInitialActive={isLensInitialActive}
            loadImageHelper={loadImageHelper}
            soundBees={soundBees}
            soundBird={soundBird}
          />
          <PositionedImage
            title="snail"
            isImgAnimated={false}
            coordinates={imageCoordinates.snail}
            imageSrc={imagesPath.snail}
            isPreloaderActive={isPreloaderActive}
            isLensInitialActive={isLensInitialActive}
            loadImageHelper={loadImageHelper}
            soundBees={soundBees}
            soundBird={soundBird}
          />
          <PositionedImage
            title="butterfly"
            isImgAnimated
            coordinates={imageCoordinates.butterfly}
            imageSrc={`${process.env.PUBLIC_URL}/images/butterflies.gif`}
            isPreloaderActive={isPreloaderActive}
            isLensInitialActive={isLensInitialActive}
            loadImageHelper={loadImageHelper}
            soundBees={soundBees}
            soundBird={soundBird}
          />
          <PositionedImage
            title="vines"
            isImgAnimated
            coordinates={imageCoordinates.vines}
            imageSrc={`${process.env.PUBLIC_URL}/images/vines.gif`}
            isPreloaderActive={isPreloaderActive}
            isLensInitialActive={isLensInitialActive}
            loadImageHelper={loadImageHelper}
            soundBees={soundBees}
            soundBird={soundBird}
          />
          <PositionedImage
            title="waterfall"
            isImgAnimated
            coordinates={imageCoordinates.waterfall}
            imageSrc={imagesPath.waterfall}
            isPreloaderActive={isPreloaderActive}
            isLensInitialActive={isLensInitialActive}
            loadImageHelper={loadImageHelper}
            soundBees={soundBees}
            soundBird={soundBird}
          />
          <PositionedImage
            title="waterfallTree"
            isImgAnimated={false}
            coordinates={imageCoordinates.waterfallTree}
            imageSrc={imagesPath.waterfallTree}
            isPreloaderActive={isPreloaderActive}
            isLensInitialActive={isLensInitialActive}
            loadImageHelper={loadImageHelper}
            soundBees={soundBees}
            soundBird={soundBird}
          />
          <PositionedImage
            title="cloud"
            isImgAnimated={false}
            coordinates={imageCoordinates.cloud}
            imageSrc={imagesPath.cloud}
            isPreloaderActive={isPreloaderActive}
            isLensInitialActive={isLensInitialActive}
            loadImageHelper={loadImageHelper}
            soundBees={soundBees}
            soundBird={soundBird}
          />
          <StaticImage
            title="scull"
            coordinates={imageCoordinates.scull}
            imageSrc={imagesPath.scull}
            loadImageHelper={loadImageHelper}
          />
          <StaticImage
            title="ants"
            coordinates={imageCoordinates.ants}
            imageSrc={imagesPath.ants}
            loadImageHelper={loadImageHelper}
          />
          <Layer
            className="layer"
            data-depth="0.7"
          >
            <StaticImage
              title="ship"
              coordinates={imageCoordinates.ship}
              imageSrc={imagesPath.ship}
              loadImageHelper={loadImageHelper}
            />
          </Layer>
          <StaticImage
            title="shadow"
            coordinates={imageCoordinates.shadow}
            imageSrc={imagesPath.shadow}
            loadImageHelper={loadImageHelper}
          />
        </StaticImage>
        <PositionedImage
          title="sand"
          coordinates={imageCoordinates.sand}
          imageSrc={imagesPath.sand}
          isPreloaderActive={isPreloaderActive}
          isLensInitialActive={isLensInitialActive}
          loadImageHelper={loadImageHelper}
          soundBees={soundBees}
          soundBird={soundBird}
        />
        <StaticImage
          title="water"
          coordinates={imageCoordinates.water}
          imageSrc={imagesPath.water}
          loadImageHelper={loadImageHelper}
        />
      </Layer>
      <Layer
        className="layer"
        data-depth="0.18"
      >
        <PositionedImage
          title="sky"
          isImgAnimated
          coordinates={imageCoordinates.sky}
          imageSrc={imagesPath.sky}
          isPreloaderActive={isPreloaderActive}
          isLensInitialActive={isLensInitialActive}
          loadImageHelper={loadImageHelper}
          soundBees={soundBees}
          soundBird={soundBird}
        />
        <StaticImage
          title="building"
          coordinates={imageCoordinates.building}
          imageSrc={imagesPath.building}
          loadImageHelper={loadImageHelper}
        />
        <PositionedImage
          title="flocks"
          isImgAnimated
          coordinates={imageCoordinates.flocks}
          imageSrc={`${process.env.PUBLIC_URL}/images/flocks.gif`}
          isPreloaderActive={isPreloaderActive}
          isLensInitialActive={isLensInitialActive}
          loadImageHelper={loadImageHelper}
          soundBees={soundBees}
          soundBird={soundBird}
        />
      </Layer>
      <StaticImage
        title="mainLogo"
        coordinates={imageCoordinates.mainLogo}
        imageSrc={imagesPath.mainLogo}
        loadImageHelper={loadImageHelper}
      />
    </Container>
  );
};

Welcome.propTypes = {
  isAnimated: propTypes.bool.isRequired,
  duration: propTypes.number.isRequired,
  isLensInitialActive: propTypes.bool.isRequired,
  isLensActive: propTypes.bool.isRequired,
  isPreloaderActive: propTypes.bool.isRequired,
  loadImageHelper: propTypes.func.isRequired,
};

export default Welcome;

const Container = styled.div`
  ${({ isAnimated }) => (isAnimated ? '' : 'opacity: 0')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  will-change: transform, filter;
  animation: ${({ isAnimated, duration }) => (isAnimated ? `transitionScreens ${duration}ms forwards` : `fadeInWelcome ${duration}ms forwards`)};
  animation-timing-function: linear;
  filter: ${({ isPreloaderActive }) => (isPreloaderActive ? 'blur(4px)' : 'none')};
  transition: filter 1s;
  
  @keyframes fadeInWelcome {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes transitionScreens {
    from {
      transform: scale(1);
      opacity: 1;
      filter: blur(0);
    }
    to {
      transform: scale(2);
      opacity: 0;
      filter: blur(4px);
    }
  }
`;

const Layer = styled.div`
  position: relative;
  transition: width 1s;
  width: 100%;
  height: 100%;
  &:first-child {
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
  &:nth-child(2) {
    right: 0;
    z-index: 900;
    pointer-events: all;
  }
  &:nth-child(3) {
    right: 0;
    z-index: 700;
  }
  .layer {
    z-index: -30;
  }
`;
