import React, { useState } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Navigation from '../components/Navigation';
import Pagination from '../components/Pagination';
import Ellipse from '../assets/images/ellipse.png';
import media from '../utils/media';

const Portfolio = (props) => {
  const {
    currentType,
    setCurrentType,
    isAnimated,
    duration,
    currentItem,
    setCurrentItem,
  } = props;
  const [animated, setAnimated] = useState(false);
  const photoListFetched = props.photoListFetched[currentType === 0 ? 'photography' : 'graphic_design'];

  if (animated) {
    setTimeout(() => setAnimated(false), 5000);
  }
  return (
    <Container
      isAnimated={isAnimated}
      duration={duration}
    >
      <NavigationContainer>
        <Navigation
          portfolio={[{"type": "Photography"}, {"type": "Graphic Design"}]}
          currentType={currentType}
          setCurrentType={setCurrentType}
          setCurrentItem={setCurrentItem}
          setAnimated={setAnimated}
        />
        <Pagination
          items={props.photoListFetched[currentType === 0 ? 'photography' : 'graphic_design']}
          setCurrentItem={setCurrentItem}
          currentItem={currentItem}
          setAnimated={setAnimated}
        />
      </NavigationContainer>
      <PortfolioContainer>
        {photoListFetched.map((item, i) => (
          <PortfolioItem
            key={item.file_path}
            isActive={i === currentItem}
          >
            <Title>{item.name}</Title>
            <img src={item.file_path} alt={item.name} />
          </PortfolioItem>
        ))}
      </PortfolioContainer>
      <Background>
        <img src={Ellipse} alt="background ellipse" />
      </Background>
    </Container>
  );
};

Portfolio.propTypes = {
  setCurrentType: propTypes.func.isRequired,
  currentType: propTypes.number.isRequired,
  isAnimated: propTypes.bool.isRequired,
  duration: propTypes.number.isRequired,
  currentItem: propTypes.number.isRequired,
  setCurrentItem: propTypes.func.isRequired,
};

export default Portfolio;

const Container = styled.div`
  ${({ isAnimated }) => (isAnimated ? '' : 'transform: scale(.7)')};
  ${({ isAnimated }) => (isAnimated ? '' : 'filter: blur(4px)')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform, filter;
  animation: ${({ isAnimated, duration }) => (isAnimated ? `transitionScreenToMain ${duration}ms forwards` : `fadeInPortfolio ${duration}ms forwards`)};
  animation-timing-function: linear;
  
  @keyframes fadeInPortfolio {
    from {
      transform: scale(.7);
      opacity: 0;
      filter: blur(4px);
    }
    to {
      transform: scale(1);
      opacity: 1;
      filter: blur(0);
    }
  }
  
  @keyframes transitionScreenToMain {
    from {
      transform: scale(1);
      opacity: 1;
      filter: blur(0);
    }
    to {
      transform: scale(.7);
      opacity: 0;
      filter: blur(4px);
    }
  }
  ${media.tablet`
    position: static;
    flex-direction: column;
    margin: 0 14px 14px;
    flex-grow: 1;
    align-items: initial;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (orientation: landscape) {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }
  `};
`;

const NavigationContainer = styled.div`
  ${media.tablet`
    margin-top: 60px;
    flex-basis: auto;
    width: 100%;
    @media (orientation: landscape) {
      flex-basis: 35%;
    }
  `};
  ${media.mobile`
    margin-top: 0;
  `}
`;

const PortfolioContainer = styled.div`
  position: relative;
  width: 500px;
  height: 500px;
  ${media.tablet`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 0;
    flex-basis: 50%;
    align-self: flex-start;
    height: 100%;
    width: 100%;
    flex-grow: 1;
    flex-basis: auto;
    @media (orientation: landscape) {
      flex-grow: 0;
      flex-basis: 50%;
    }
  `};
  ${media.mobile`
    flex-grow: 1;
    flex-basis: auto;
    @media (orientation: landscape) {
      flex-grow: 0;
      flex-basis: 50%;
    }
  `}
    @media (max-height: 360px) and (orientation: landscape) {
      width: 70%;
      height: 200px;
    }
`;

const PortfolioItem = styled.div`
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  filter: blur(${({ isActive }) => (isActive ? '0' : '20px')});
  transform: scale(${({ isActive }) => (isActive ? '1' : '.5')});
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  ${media.tablet`
    height: 100%;
    padding-bottom: 10px;
    img {
      height: 90%;
      top: 0;
      left: 0;
      object-fit: contain;
    }
    @media (max-height: 360px) {
      width: 80%;
    }
    @media (orientation: landscape) {
      img {
        height: 90%;
      }
    }
  `};
  ${media.mobile`
    @media (orientation: landscape) {
      img {
        height: 90%;
      }
    }
  `};
`;

const Title = styled.h1`
  color: #fff;
  font-family: "Source Sans Pro - Semibold", sans-serif;
  font-size: 60px;
  margin-bottom: 29px;
  ${media.tablet`
    font-size: 25px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 20px;
    margin-bottom: 29px;
    margin-top: 0;
    @media (orientation: landscape) {
      font-size: 25px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  `};
  ${media.mobile`
    font-size: 20px;
    margin-bottom: 29px;
    margin-top: 0;
    @media (orientation: landscape) {
      font-size: 25px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  `}
`;

const Background = styled.div`
  position: fixed;
  left: 11.3636%;
  top: -74px;
  width: 71.09375%;
  height: 100%;
  z-index: -10;
  img {
    pointer-events: none;
    width: 100%;
  }
  ${media.tablet`
    display: none;
    position: absolute;
    bottom: 0;
    top: auto;
    height: auto;
  `}
`;
