const desktopWidth = 1920 / 100;
const desktopHeight = 1080 / 100;
const mobileWidth = 1080 / 100;
const mobileHeight = 1920 / 100;

const modelDesktopWidth = 1055 / 100;
const modelMobileWidth = 855 / 100;
const modelDesktopHeight = 795 / 100;
const modelMobileHeight = 644 / 100;

const newImageCoordinates = {
  grass: {
    desktop: `
      width: ${971 / desktopWidth}%;
      bottom: 0;
      right: ${-158 / desktopWidth}%;
    `,
    mobile: `
      width: ${787 / mobileWidth}%;
      right: ${-158 / desktopWidth}%;
      @media (orientation: landscape) {
        width: ${971 / desktopWidth}%;
        right: ${-158 / desktopWidth}%;
      }
    `,
  },
  ants: {
    desktop: `
        width: ${257 / modelDesktopWidth}%;
        left: ${502 / modelDesktopWidth}%;
        height: ${93 / modelDesktopHeight}%;
        bottom: ${-40 / modelDesktopHeight}%;
        z-index: -20;
      `,
    mobile: '',
  },
  bees: {
    desktop: `
        width: ${92 / modelDesktopWidth}%;
        height: ${50 / modelDesktopHeight}%;
        left: ${298 / modelDesktopWidth}%;
        bottom: ${721 / modelDesktopHeight}%;
      `,
    mobile: '',
    isImgAnimated: true,
  },
  bird: {
    desktop: `
        width: ${120 / modelDesktopWidth}%;
        height: ${90 / modelDesktopHeight}%;
        top: ${-59 / modelDesktopHeight}%;
        left: ${551 / modelDesktopWidth}%;
        z-index: 900;
      `,
    mobile: '',
    isImgAnimated: true,
  },
  butterfly: {
    desktop: `
        width: ${300 / modelDesktopWidth}%;
        left: ${330 / modelDesktopWidth}%;
        height: ${250 / modelDesktopHeight}%;
        bottom: ${755 / modelDesktopHeight}%;
        z-index: 850;
      `,
    mobile: '',
    isImgAnimated: true,
  },
  cloud: {
    desktop: `
        width: ${896 / modelDesktopWidth}%;
        left: ${189 / modelDesktopWidth}%;
        height: ${532 / modelDesktopHeight}%;
        bottom: ${616 / modelDesktopHeight}%;
        z-index: -20;
      `,
    mobile: '',
  },
  mainLogo: {
    desktop: `
      width: ${305 / desktopWidth}%;
      height: ${195 / desktopHeight}%;
      left: ${510 / desktopWidth}%;
      bottom: ${166 / desktopHeight}%;
      top: ${719 / desktopHeight}%;
    `,
    mobile: `
        width: ${496 / mobileWidth}vw;
        left: ${295 / mobileWidth}vw;
        right: ${289 / mobileWidth}vw;
        height: ${316 / mobileHeight}%;
        top: ${288 / mobileHeight}%;
        bottom: ${476 / mobileHeight}%;
        @media (orientation: landscape) {
          width: ${305 / desktopWidth}%;
          height: ${195 / desktopHeight}%;
          left: ${510 / desktopWidth}%;
          bottom: ${166 / desktopHeight}%;
          top: ${719 / desktopHeight}%;
        }
      `,
  },
  model: {
    desktop: `
        width: ${1055 / desktopWidth}%;
        left: ${470 / desktopWidth}%;
        right: ${395 / desktopWidth}%;
        bottom: ${32 / desktopHeight}%;
        z-index: 950;
        @media (max-height: 690px) {
          width: ${850 / desktopWidth}%;
          left: ${670 / desktopWidth}%;
        }
      `,
    tablet: `
        @media (max-height: 360px) {
          width: ${600 / desktopWidth}%;
        }
    `,
    mobile: `
        width: ${855 / mobileWidth}%;
        bottom: ${26 / mobileHeight}%;
        right: ${276 / mobileWidth}%;
        left: ${-51 / mobileWidth}%;
        @media (orientation: landscape) {
          width: ${1055 / desktopWidth}%;
          left: ${470 / desktopWidth}%;
          right: ${395 / desktopWidth}%;
          bottom: ${32 / desktopHeight}%;
        }
        @media (max-height: 360px) {
          width: ${600 / desktopWidth}%;
        }
      `,
  },
  sand: {
    desktop: `
      width: ${721 / desktopWidth}%;
      right: ${395 / desktopWidth}%;
      height: ${120 / desktopHeight}%;
      bottom: ${0 / desktopHeight}%;;
      z-index: -20;
    `,
    mobile: `
      width: ${585 / mobileWidth}%;
      height: ${97 / mobileHeight}%;
      right: ${276 / mobileWidth}%;
      @media (orientation: landscape) {
        width: ${721 / desktopWidth}%;
        right: ${395 / desktopWidth}%;
        height: ${120 / desktopHeight}%;
      }
    `,
  },
  scull: {
    desktop: `
        width: ${165 / modelDesktopWidth}%;
        left: ${454 / modelDesktopWidth}%;
        height: ${141 / modelDesktopHeight}%;
        bottom: ${-5 / modelDesktopHeight}%;
        z-index: 800;
      `,
    mobile: '',
  },
  shadow: {
    desktop: `
      width: ${1242 / modelDesktopWidth}%;
      left: ${-97 / modelDesktopWidth}%;
      bottom: ${-34 / modelDesktopHeight}%;
      z-index: -20;
    `,
    mobile: '',
  },
  snail: {
    desktop: `
        width: ${83 / modelDesktopWidth}%;
        left: ${744 / modelDesktopWidth}%;
        height: ${100 / modelDesktopHeight}%;
        bottom: ${333 / modelDesktopHeight}%;
        z-index: 900;
      `,
    mobile: '',
  },
  vines: {
    desktop: `
        width: ${169 / modelDesktopWidth}%;
        left: ${697 / modelDesktopWidth}%;
        height: ${225 / modelDesktopHeight}%;
        top: ${180 / modelDesktopHeight}%;
        z-index: 900;
      `,
    mobile: '',
    isImgAnimated: true,
  },
  water: {
    desktop: `
        bottom: ${-20 / desktopHeight}%;
        right: ${-91 / desktopWidth}%;
        width: ${713 / desktopWidth}%;
        z-index: 750;
      `,
    mobile: `
      width:${577 / mobileWidth}%;
      height: ${161 / mobileHeight}%;
      bottom: 0;
      // right: ${-117 / mobileWidth}%;
      @media (orientation: landscape) {
        bottom: ${-15 / mobileHeight}%;
        right: ${-177 / mobileWidth}%;
      }
    `,
  },
  waterfall: {
    desktop: `
        width: ${241 / modelDesktopWidth}%;
        left: ${886 / modelDesktopWidth}%;
        height: ${489 / modelDesktopHeight}%;
        bottom: ${8 / modelDesktopHeight}%;
        z-index: 900;
      `,
    mobile: '',
    isImgAnimated: true,
  },
  waterfallTree: {
    desktop: `
        width: ${312 / modelDesktopWidth}%;
        left: ${857 / modelDesktopWidth}%;
        height: ${217 / modelDesktopHeight}%;
        bottom: ${408 / modelDesktopHeight}%;
        z-index: -10;
      `,
    mobile: '',
  },
  ship: {
    desktop: `
        width: ${435 / modelDesktopWidth}%;
        left: ${731 / modelDesktopWidth}%;
        bottom: ${651 / modelDesktopHeight}%;
        height: ${379 / modelDesktopHeight}%;
        z-index: -20;
        @keyframes ship {
          0% {
            transform: translateY(0);
          }
          75% {
            transform: translateY(-100%);
          }
          100% {
            transform: translateY(0);
          }
        };
        img {animation: ship 13s infinite;};
      `,
    mobile: `
      bottom: ${821 / modelMobileHeight}%;
      left: ${473 / modelMobileWidth}%;
    `,
    isImgAnimated: false,
  },
  building: {
    desktop: `
        width: ${533 / desktopWidth}%;
        left: ${1409 / desktopWidth}%;
        right: ${-22 / desktopWidth}%;
        height: ${393 / desktopHeight}%;
        top: ${687 / desktopHeight}%;
        bottom: ${0 / desktopHeight}%;
        z-index: 600;
      `,
    mobile: `
        width: ${432 / mobileWidth}%;
        right: ${-46 / mobileWidth}%;
        height: ${318 / mobileHeight}%;
        top: auto;
      `,
  },
  flocks: {
    desktop: `
      width: ${150 / desktopWidth}%;
      height: ${127 / desktopHeight}%;
      right: ${0 / desktopWidth}%;
      bottom: ${435 / desktopHeight}%;
      top: ${518 / desktopHeight}%;
      z-index: 600;
    `,
    mobile: `
        width: ${228 / mobileWidth}%;
        left: ${872 / mobileWidth}%;
        right: ${-20 / mobileWidth}%;
        height: ${80 / mobileHeight}%;
        bottom: ${360 / mobileHeight}%;
        top: ${1480 / mobileHeight}%;
      `,
    isImgAnimated: true,
  },
  sky: {
    desktop: `
        width: ${1308 / desktopWidth}%;
        height: ${623 / desktopHeight}%;
        top: 0;
        right: -17px;
        z-index: 600;
      `,
    mobile: `
      width: 100%;
      height: ${733 / mobileHeight}%;
    `,
    isImgAnimated: true,
  },
};

export default newImageCoordinates;
